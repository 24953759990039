<template>
  <div class="panel">
    <slot />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.panel {
    box-shadow: -2px 2px 8px rgba(0, 0, 0, 0.06);
    border-radius: 2px;
    background-color: #FFFFFF;

  @apply rounded-lg shadow pt-6 pb-12 px-8;

  @screen sm {
    @apply px-12;
  }

  @screen xl {
    @apply pt-12 pb-10 px-12;
  }

}
</style>